.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  margin-top: 75px;
}

.right-align {
  margin-left: auto !important;
}

.cart-item {
  border: 1px solid grey;
  margin: 20px;
  padding: 10px;
}

.cart-qty-section {
  width: 100px !important;
}

.cart-paper {
  padding: 10px 0px;
}

.about-paper {
  padding: 10px;
}

.about-div {
  text-align: left;
  padding: 0px 10px 10px 10px;
}

.modal-div {
  padding-top: 10px;
}

.form-div {
  padding-bottom: 20px;
}

.form-divider {
  padding: 20px 0px;
}

.body-logo {
  padding-bottom: 45px;
}

.header-title {
  cursor: pointer;
}

.loading-spinner {
  padding-left: 48%;
  text-align: left;
}

.button-div {
  margin-bottom: 10px;
}

.item-card {
  width: 340px
}

.item-card-price {
  padding-left: 75px;
}

.item-card-sold-out {
  padding-left: 55px;
}

.item-card-content {
  padding: 5px 5px 0px 5px !important;
  height: 60px;
}

.item-details-text {
  width: 100%;
}

.input-component {
  width: 300px;
}

.payment-div {
  width: 300px;
  margin: auto;
  margin-bottom: 10px;
}

.email-icon {
  font-size: 83px !important;
  margin-bottom: -12px;
  padding-left: 10px;
  color: #768d79;
}

.cart-list-item-column {
  margin-top: 18px !important;
}

.checkbox {
  padding: 0px !important
}

.checkbox-dev {
  padding-bottom: 15px;
}